<template>
  <div>
    <Header></Header>
    <section class="fixed">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-sm-12 mx-auto">
            <div class="error-container">
              <span>404</span>
              <h1>ページが見つかりません。</h1>
              <p>お探しのページが見つかりません。一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。URLのご確認をお願いいたします。</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/views/components/Footer";
import Header from "@/views/components/Header";
import MixIn from "@/common/mixin";
export default {
  name: "NotFound",
  components: {Header, Footer},
  mixins: [MixIn]
}
</script>

<style scoped>

</style>
